.gallery__swiper,
.thumbnail__swiper,
.responsive__swiper {
  width: 100%;
  height: 100%;
}

.main__slider {
  width: 100%;
  height: 518px;
  position: relative;
  &:hover {
    .horizontal__slider__prev,
    .horizontal__slider__next {
      display: block;
    }
    .swiper-button-lock {
      display: none;
    }
  }
}

.thumbs__slider {
  height: 517px;
  position: relative;
  margin-inline-end: 24px;

  .vertical__slider__image {
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    border: 1px solid var(--lightGray);
    background-color: #ebedef;
    img {
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      mix-blend-mode: multiply;
    }
  }
  .swiper-slide-thumb-active {
    .vertical__slider__image {
      border: 2px solid rgba(var(--deepYellowRgb), 0.2);

      img {
        border: 1px solid var(--deepYellow);
      }
    }
  }
}

.responsive__swiper {
  max-height: inherit;
  .swiper-wrapper {
    max-height: inherit;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--lightGray);
    background-color: #ebedef;
    max-height: inherit;
    height: auto;
    img {
      mix-blend-mode: multiply;
      max-height: inherit;
    }
  }
}

.horizontal__slider__prev,
.horizontal__slider__next,
.vertical__slider__prev,
.vertical__slider__next {
  cursor: pointer;
  z-index: 99;
  display: none;
  &.swiper-button-disabled {
    display: none;
  }
}

.horizontal__slider__prev {
  position: absolute;
  left: 24px;
  height: max-content;
  top: 50%;
  transform: translateY(-50%);
}
.horizontal__slider__next {
  position: absolute;
  right: 24px;
  height: max-content;
  top: 50%;
  transform: translateY(-50%);
}
.vertical__slider__prev {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.vertical__slider__next {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  display: flex;
  justify-content: center;
}
