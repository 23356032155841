@keyframes slideRight {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

.animate__arrow:hover {
  svg {
    animation: slideRight 1s ease-in-out infinite;
    margin-inline-end: 8px;
  }
}
