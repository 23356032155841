.main__container {
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--midLightGray);
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: var(--antiFlashWhite);
  }
}

.categories__container {
  position: absolute;
  display: flex;
  background-color: var(--white);
  color: var(--midDarkGray);
  max-height: 70vh;
  border: 1px solid var(--lightGray);
  z-index: 9999;
  margin-top: 8px;
  border-radius: var(--border-radius-normal);
}

.partition__container__left {
  overflow-y: auto;
  padding: 24px;
  background-color: var(--secondaryLight);
  border-top-left-radius: var(--border-radius-normal);
  border-bottom-left-radius: var(--border-radius-normal);
}

.partition__container__right {
  overflow-y: auto;
  padding: 24px;
  background-color: var(--white);
  border-top-right-radius: var(--border-radius-normal);
  border-bottom-right-radius: var(--border-radius-normal);
}

.category__item {
  position: relative;
  padding-block: 4px;
  display: flex;
  align-items: center;
  padding-inline: 12px;
  justify-content: space-between;
  min-height: 50px;
}

.category__item:hover,
.category__item.selected {
  background-color: var(--white);
  margin: 0 -22px;
  padding-inline: calc(12px + 22px);
  .vertical__selector {
    display: block;
  }
}

.sub__category__item {
  position: relative;
  padding-block: 4px;
  display: flex;
  align-items: center;
  padding-inline: 12px;
  justify-content: space-between;
  min-height: 50px;
}

.sub__category__item:hover {
  background-color: var(--secondaryLight);
  margin: 0 -22px;
  padding-inline: calc(12px + 22px);
  .vertical__selector {
    display: block;
  }
}

.vertical__selector {
  display: none;
  position: absolute;
  right: 0;
  height: 100%;
  width: 8px;
  background-color: var(--deepYellow);
}
