.packaging__delivery__body {
  color: var(--midDarkGray) !important;
  font-family: 'Lato' !important ;
  * {
    color: var(--midDarkGray) !important;
    font-family: 'Lato' !important ;
  }
  td {
    border: 1px solid var(--lightGray);
    padding: 20px 16px !important;
    font-family: 'Lato';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--midDarkGray);
    text-align: center;
  }
}

.lead__time {
  color: var(--midDarkGray) !important;
  font-family: 'Lato' !important ;
  * {
    color: var(--midDarkGray) !important;
    font-family: 'Lato' !important ;
  }
  td {
    border: 1px solid var(--lightGray);
    padding: 20px 16px !important;
    font-family: 'Lato';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--midDarkGray);
    text-align: center;
  }
}
