.main__toolbar__container__responsive {
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .main__toolbar__container__responsive {
    display: none;
  }
}
