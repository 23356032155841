.search__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.search__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--magentaPink);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
  aspect-ratio: 1/1;
  order: 5;
}

@media (max-width: 768px) {
  .search__box {
    max-width: 100%;
    width: 100%;
  }
}
