.message__center__title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  padding: 0 24px;
  cursor: pointer;
  background-color: var(--white);
  color: var(--deepYellow);
  border-left: 1px solid var(--lightGray);
}

.message__center__title.active {
  background-color: var(--deepYellow);
  color: var(--white);
}

.notification__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--white);
  color: var(--deepYellow);
}
