.sendbird-channel-list__header {
  min-height: 0px;
  height: auto;
}
.sendbird-channel-preview {
  width: auto;
}
.sendbird-channel-list {
  width: auto;
}
.sendbird-channel-list__body > div {
  height: calc(100vh - 109px);
}

@media (max-width: 768px) {
  .sendbird-fileviewer__header__left {
    max-width: 50%;
  }
}
