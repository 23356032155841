.other__main__header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 20px;
  gap: 48px;
}

.other__main__header__autocomplete__container {
  height: var(--autocomplete-other-height);
  flex-grow: 1;
}

.account__menu {
  height: 20px;
  display: flex;
  align-items: center;
  flex-grow: 0;
  text-align: center;
}
