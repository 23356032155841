.float__card__container-enter {
  opacity: 0;
  transform: scale(0.9);
}
.float__card__container-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 300ms,
    transform 300ms;
}
.float__card__container-exit {
  opacity: 1;
}
.float__card__container-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition:
    opacity 300ms,
    transform 300ms;
}
