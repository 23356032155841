.rfq__card {
  padding: 16px 20px;
  box-shadow: 0.5px 0.5px 2px 0px rgba(0, 0, 0, 0.25) !important;
  margin-bottom: 20px;
}

.index__col__container {
  display: flex;
  align-items: center;
  padding-inline: 40px;

  .product__name {
    display: flex;
    flex-direction: column;
    max-width: max-content;
    overflow: hidden;
  }
}
