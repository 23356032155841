.menu__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 12px;
}

.menu__item__icon {
  width: 24px;
  height: 24px;
}

.user__name {
  color: var(--midLightGray) !important;
  margin-inline-end: 4px;
  text-align: start;
  font-weight: normal !important;
}

.drawer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: var(--secondaryLight);
  padding: 20px;
  gap: 12px;
  h3 {
    color: var(--darkGray);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
