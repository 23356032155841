.rfq__container {
  margin-top: -45px;
  display: flex;
  overflow: hidden;
}

.rfq__art {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  gap: 20px;
  padding: 100px 0 0;
}

.rfq__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  padding: 40px 0 64px;
  background-color: var(--background);
  box-shadow: var(--boxShadow);
}

.button__container {
  display: flex;
  justify-content: center;
  margin-block: 40px;
}

.upload__container {
  margin-bottom: 40px;
}

@media (pointer: coarse) {
  .rfq__container {
    margin-top: 0;
  }

  .rfq__form {
    padding: 12px 20px 40px;
    margin-top: 20px;
    margin-bottom: 60px;
    width: 100%;
  }

  .button__container {
    margin-block: 20px;
  }
}
