.sendbird-theme--light .sendbird-text-message-item-body.outgoing {
  background-color: var(--deepYellow);
  color: var(--white);
  border-radius: 0px;
  font-family: 'Lato';
  font-size: 14px;
}
.sendbird-theme--light .sendbird-text-message-item-body.mouse-hover.outgoing {
  background-color: var(--deepYellow);
}

.sendbird-theme--light .sendbird-text-message-item-body.incoming {
  background-color: var(--secondaryLight);
  color: var(--darkGray);
  border-radius: 0px;
  font-family: 'Lato';
  font-size: 14px;
}

.sendbird-conversation__footer {
  background-color: var(--antiFlashWhite);
  padding: unset;
  padding-inline: 24px;
  padding-block: 16px;
}

.sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea {
  border-radius: 0px;
  border: 0px;
  overflow: hidden;
}

.sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea:focus {
  border: solid 1px var(--deepYellow);
}

.sendbird-message-input .sendbird-message-input--send {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--deepYellow) !important;
  border-radius: 50%;
  height: 38px !important;
  width: 38px !important;
  bottom: 10px !important;
}
.sendbird-iconbutton__inner {
  height: 20px !important;
}
.sendbird-theme--light .sendbird-icon-color--primary [class*='fill'] {
  fill: var(--white) !important;
}

.sendbird-theme--light .sendbird-label--color-oncontent-1 {
  color: var(--white);
}
.sendbird-theme--light .sendbird-voice-message-item-body__status-button__button {
  background-color: var(--magentaPink);
}

.sendbird-separator .sendbird-separator__left {
  height: 0px;
}

.sendbird-separator .sendbird-separator__right {
  height: 0px;
}

.sendbird-theme--light .sendbird-message-input-wrapper--voice-message {
  border: none;
}

.sendbird-voice-message-input {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: unset;
  justify-content: center;
  padding-top: unset;
  padding-left: unset;
  padding-right: unset;
  box-sizing: border-box;
}
.sendbird-theme--light .sendbird-progress-bar.progress-bar--disabled {
  background-color: var(--white);
}

.sendbird-message-input-wrapper .sendbird-message-input-wrapper__message-input {
  padding: 0px;
}

.sendbird-theme--light .progress-bar-color--primary {
  background-color: var(--deepYellow);
}

.sendbird-theme--light .sendbird-text-message-item-body:hover.outgoing {
  background-color: var(--deepYellow);
}

.sendbird-theme--light .sendbird-file-message-item-body.outgoing {
  background-color: var(--deepYellow);
}

.sendbird-theme--light .sendbird-label--color-primary {
  color: var(--magentaPink);
}
.sendbird-theme--light .sendbird-icon-color--content-inverse [class*='fill'] {
  fill: var(--midDarkGray);
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__sender-name {
  overflow: hidden;
}
.sendbird-file-message-item-body__file-icon,
.sendbird-message-content__middle__message-item-body,
.sendbird-thumbnail-message-item-body__thumbnail,
.sendbird-thumbnail-message-item-body,
.sendbird-voice-message-item-body.sendbird-message-content__middle__message-item-body {
  border-radius: 0px !important;
}
.sendbird-og-message-item-body {
  min-width: 200px !important;
  max-width: 300px !important;
}
.sendbird-modal {
  z-index: 1102;
}
.sendbird-image-renderer,
.sendbird-image-renderer__image {
  height: 120px !important;
  width: 312px !important;
}
@media (max-width: 768px) {
  .sendbird-conversation__footer {
    position: sticky;
    bottom: 0;
    z-index: 1000;
  }
  .sendbird-theme--light .sendbird-text-message-item-body.incoming {
    font-size: 12px;
  }
  .sendbird-theme--light .sendbird-text-message-item-body.outgoing {
    font-size: 12px;
  }
}
