.swiper__slide {
  width: 7.75% !important;
  aspect-ratio: 1;
  cursor: pointer;
  .outline__box {
    width: 100%;
    height: 100%;
    .border__box {
      width: 100%;
      height: 100%;
      padding: 4px;
      .color__bg {
        width: 100%;
        height: 100%;
        border: 1px solid var(--lightGray);
      }
    }
  }
}

.slider__container {
  width: 100%;
  position: relative;
}

.swiper__slide[aria-selected='true'] {
  .outline__box {
    outline: 2px solid rgba(var(--deepYellowRgb), $alpha: 0.2);
    .border__box {
      border: 1px solid var(--deepYellow);
      padding: 4px;
    }
  }
}

@media (max-width: 768px) {
  .active__slide {
    .outline__box {
      .border__box {
        padding: 2px;
      }
    }
  }
  .swiper__slide {
    width: 11% !important;
  }
}
