.step {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--darkGray);
  padding: 8px;
}

.step.active {
  color: var(--magentaPink);
  .step__circle {
    background-color: var(--magentaPink);
  }
}

.step.disabled {
  color: var(--midLightGray);
  .step__circle {
    background-color: var(--midLightGray);
  }
}

.step__circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: var(--white);
}

.success__icon {
  width: 32px;
  height: 32px;
}

@media (max-width: 768px) {
  .step {
    gap: 4px;
  }
  .step__circle {
    width: 20px;
    height: 20px;
  }
  .success__icon {
    width: 20px;
    height: 20px;
  }
}
