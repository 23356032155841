/* Popular */
.aa-PanelSection--popular .aa-List {
  display: flex;
  flex-wrap: wrap;
  gap: var(--aa-spacing-half);
}

.aa-PanelSection--popular .aa-ItemContentTitle {
  margin: 0;
}

.aa-PanelSection--popular .aa-ItemWrapper {
  grid-gap: var(--aa-spacing-half);
}

.aa-PanelSection--popular .aa-Item {
  background-color: var(--secondaryLight);
  border-radius: 0 !important ;
  padding: var(--aa-spacing-half);
  font-size: 0.9em;
}

.aa-PanelSection--popular .aa-Item:hover {
  background-color: var(--antiFlashWhite) !important;
}

.aa-PanelSection--popular .aa-ItemIcon {
  width: var(--aa-spacing);
  height: var(--aa-spacing);
}
