/* Products */
.aa-PanelSection--products {
  transition: background 0.2s ease-out;
}

.aa-PanelSection--products .aa-List {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.aa-PanelSection--products .aa-Item {
  align-items: flex-start;
  width: 100%;
  padding: var(--aa-spacing-half);
}
.aa-PanelSection--products .aa-Item:hover {
  background-color: unset !important;
}

.aa-ProductItem {
  height: 100%;
  border: 1px solid var(--lightGray);
  padding: 12px;
}

.aa-ProductItem.aa-ItemLink {
  align-items: flex-start;
  justify-content: stretch;
}

.aa-ProductItem .aa-ItemContent mark {
  color: rgb(var(--aa-primary-color-rgb));
}

.aa-ProductItem .aa-ItemPicture {
  position: relative;
  width: 195px;
  aspect-ratio: 1.6;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.aa-ProductItem .aa-ItemContentBody {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.aa-ProductItem .aa-ItemContent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 24px;
}

.aa-ProductItem .aa-ItemContentTitle {
  font-size: 0.9em;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}

.aa-ProductItem .aa-ItemContentPriceCurrent {
  display: flex;
  align-items: center;
}

.aa-ProductItem .aa-ItemContentPriceDiscounted {
  font-size: 0.9em;
  text-decoration: line-through;
  color: rgb(var(--aa-muted-color-rgb));
}

.aa-ProductItem .aa-ItemContentPrice {
  display: flex;
  column-gap: var(--aa-spacing-half);
}

.aa-ProductItem .aa-ItemFavorite {
  z-index: 5;
  position: absolute;
  right: var(--aa-spacing-half);
  top: var(--aa-spacing-half);
  background-color: #fff;
  border-radius: calc(var(--aa-spacing-half) / 2);
  box-shadow: 0px 4px 8px rgba(35, 38, 59, 0.15);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.aa-ProductItem .aa-FavoriteIcon {
  color: rgb(var(--aa-primary-color-rgb));
  stroke-width: 2;
  stroke: currentColor;
  margin: 1px;
}

.aa-ProductItem .aa-FavoriteIcon--outlined {
  fill: none;
}

@media (max-width: 768px) {
  .aa-ProductItem .aa-ItemContent {
    gap: 12px;
  }
  .aa-ProductItem .aa-ItemContentBody {
    gap: 8px;
  }
  .aa-ProductItem .aa-ItemPicture {
    width: 137px;
  }
}
@media (max-width: 390px) {
  .aa-ProductItem .aa-ItemPicture {
    width: 115px;
  }
}
