.aa-PanelSection--popularCategories .aa-List {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--aa-spacing);
  font-size: 0.9em;
}

.aa-PanelSection--popularCategories .aa-Item {
  padding: 0;
}

.aa-PanelSection--popularCategories .aa-SourceHeader {
  margin-top: 0;
}

.aa-PopularCategoryItem.aa-ItemWrapper {
  justify-content: stretch;
}

.aa-PopularCategoryItem .aa-ItemContent {
  grid-auto-flow: row;
}

.aa-PopularCategoryItem .aa-ItemContentTitle {
  margin-right: 0;
}

.aa-PopularCategoryItem .aa-ItemContentTitle span {
  font-size: 0.8em;
  color: rgb(var(--aa-muted-color-rgb));
}
