.vendor__container {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  .vendor__icon__container {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--secondaryLight);
    margin-inline-end: 8px;
  }
}

.card__container {
  position: sticky;
  top: calc(145px + 20px);
}

.product__description__tab {
  color: var(--midDarkGray) !important;
  font-family: 'Lato' !important ;
  * {
    color: var(--midDarkGray) !important;
    font-family: 'Lato' !important ;
  }
  td {
    border: 1px solid var(--lightGray);
    padding: 20px 16px !important;
    font-family: 'Lato';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--midDarkGray);
    text-align: center;
  }
  strong {
    font-weight: 700;
    font-size: 16px;
  }

  ul {
    margin: 12px 0;
    padding: 0 0 0 36px;
    list-style: disc;
  }
  li {
    line-height: 30px;
  }
  @media (max-width: 576px) {
    ul {
      margin: 12px 0;
      padding: 0 0 0 20px;
      list-style: disc;
    }
  }
}
