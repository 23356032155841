.product__media__container {
  max-height: 561px;
}

.fraction__container {
  z-index: 999;
  position: absolute;
  padding: 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--darkGrayRgb), $alpha: 0.5);
  bottom: 38px;
  left: 0;
  right: 0;
  width: max-content;
  margin: auto;
}
