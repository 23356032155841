.results__header {
  min-height: 70px;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .results__header {
    min-height: 48px;
    margin-bottom: 12px;
  }
}
