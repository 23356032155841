.vendor__quotation__container {
  margin-top: 24px;
}

@media (max-width: 767px) {
  .vendor__quotation__container {
    background-color: var(--secondaryLight);
    padding: 20px;
  }
}
