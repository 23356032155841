.responsive__gallery__swiper {
  margin-bottom: 32px;
  .swiper-slide {
    border: 1px solid var(--lightGray);
    background-color: #ebedef;
    img {
      mix-blend-mode: multiply;
    }
  }
}

.responsive__thumbs__swiper {
  .slider__image {
    border: 1px solid var(--lightGray);
  }
  .swiper-slide-thumb-active {
    .slider__image {
      border: 2px solid rgba(var(--deepYellowRgb), 0.2);

      img {
        outline: 1px solid var(--deepYellow);
        outline-offset: -1px;
      }
    }
  }
}
