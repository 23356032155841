.sendbird-channel-list__header {
  min-height: 0px;
  height: auto;
}
.sendbird-channel-preview {
  width: auto;
}
.sendbird-channel-list {
  width: auto;
}
.sendbird-channel-list__body > div::after {
  content: '';
  display: block;
  height: 100px;
}
.sendbird-conversation__scroll-bottom-button {
  background-color: var(--magentaPink) !important;
}
.sendbird-channel-list__body {
  cursor: pointer;
}
