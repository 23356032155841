.filter___actions{
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 56px;
  width: 100%;
  height: 76px;
  right: 0px;
  z-index: 10;
  align-items: center;
  box-shadow: 0.5px -0.5px 2px 0px rgba(0, 0, 0, 0.24) !important;
}