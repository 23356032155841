.results__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondaryLight);
  min-height: 70px;
  padding-inline: 28px;
  margin-bottom: 24px;
}

@media (pointer: coarse)  {
  .results__header {
    min-height: 48px;
    padding-inline: unset;
    margin-bottom: 12px;
    background-color: unset;
  }
}
