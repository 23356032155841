.slick-dots {
  bottom: 10px;
}
.slick-next {
  right: 0;
}
.slick-prev {
  left: 0;
}

.slick-prev {
  margin-inline-start: -20px;
}

.slick-next {
  margin-inline-end: -20px;
}

.slick-prev:before,
.slick-next:before {
  color: unset;
  content: unset;
}

.slick-disabled {
  display: none !important;
}
