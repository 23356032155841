.message__center__channel__header {
  height: 53px;
  display: flex;
  align-items: center;
  padding-inline: 20px;
  position: sticky;
  z-index: 1000;
  position: sticky;
  background-color: var(--white);
}
