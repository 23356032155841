.footer__container {
  background-color: var(--violet);
  padding: 73px 114px;
}

@media (max-width: 576px) {
  .footer__container {
    padding: 50px 0;
    margin-bottom: 30px;
  }
}
