.products__slider {
  position: relative;
}

.product__card {
  width: 240px;
}

@media (max-width: 767px) {
  .product__card {
    width: 158px;
  }
}

@media (max-width: 414px) {
  .product__card {
    width: 135px;
  }
}
