.banner__slide {
  border-radius: 0.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 285px !important;
  a {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 767px) {
  .banner__slide {
    height: 115px !important;
  }
}
