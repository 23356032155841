.img__magnifier__container {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--lightGray);
  background-color: #ebedef;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.magnifier__img {
  width: auto;
  transition: transform 0.3s ease-in-out;
  mix-blend-mode: multiply;
}

.img__magnifier__container:hover .magnifier__img {
  cursor: crosshair;
  transform: scale(2.5);
}
