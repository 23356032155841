.privacy {
  overflow: auto;
  height: 100%;
  p {
    color: var(--midDarkGray);
  }

  h2,
  h3 {
    font-family: "Lato";
    color: var(--darkGray);
    padding: 16px 0 6px 0;
    font-weight: bold;
    font-size: 1rem;
    line-height: 24px;
  }
}
