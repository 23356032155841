.message__center__popup {
  box-shadow:
    -0.5px -0.5px 4px 0px rgba(0, 0, 0, 0.25),
    -0.5px -0.5px 10px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  bottom: 0px;
  right: 3rem;
  overflow: hidden;
  z-index: 1101;
  transform: translateX(-40px);
}
