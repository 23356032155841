body #hubspot-messages-iframe-container.widget-align-right {
  transform: translateY(-75px);
  max-height: 91% !important;
}
@media (min-width: 768px) {
 #hubspot-messages-iframe-container.widget-align-right {
    zoom: 1.1
  } 
  
  }
