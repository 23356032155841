.verified__icon {
  position: absolute;
  right: -4px;
  bottom: -4px;
  opacity: 1;
}

.verified__box {
  position: absolute;
  opacity: 0;
}

.verified__box.show {
  display: block;
  animation: moveUp 2s ease forwards;
}

@keyframes moveUp {
  0% {
    bottom: -14px;
    right: -4px;
    opacity: 0;
  }

  100% {
    right: -4px;
    bottom: -4px;
    opacity: 1;
  }
}
