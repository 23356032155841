.checked__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50vw;
  max-width: 530px;
  padding-top: 80px;
}
.lottie__container {
  width: 92px;
  height: 92px;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .checked__container {
    width: 100%;
  }
}
