.sign__card {
  padding: 24px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--antiFlashWhite);
  outline: none;
  box-shadow: none !important;
  border-radius: 16px !important;
}
.card__icon {
  width: 40px;
  height: 40px;
}
.accordion__icon {
  height: 24px;
  width: 24px;
  stroke-width: 2;
  stroke: var(--violet);
  transform: rotate(90deg);
}

.data__description {
  color: var(--midDarkGray) !important;
  font-size: 16px !important;
  * {
    color: var(--midDarkGray) !important;
    font-size: 16px !important;
  }
  ul {
    padding: 0 0 0 36px;
    list-style: disc;
    color: var(--midDarkGray);
  }
  li {
    line-height: 30px;
  }
  strong {
    font-weight: normal;
  }
}

@media (max-width: 768px) {
  .data__description {
    font-size: 14px;
  }
}
