.page__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100vh;
  width: 100%;
}
@media (max-width: 767px) {
  .page__container {
    height: 80vh;
  }
}
