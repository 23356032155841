.card {
  border: 1px solid rgba(232, 232, 234, 1);
  border-radius: 20px !important;
}

.card__action__area {
  padding: 1rem !important;
}

.card__media {
  border-radius: 16px !important;
  height: 240px;
}

.card__content {
  padding: unset !important;
  padding-top: 1rem !important;
  padding-bottom: 0.5rem !important;
}

.chip {
  margin-bottom: 1rem !important;
  margin-inline-end: 4px;
}

.card__footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--midGray);
}

.card__avatar__name {
  display: flex;
  align-items: center;
  img {
    margin-inline-end: 12px;
    width: 36px;
  }
}

@media (max-width: 768px) {
  .card {
    border-radius: 10px !important;
  }

  .card__action__area {
    padding: 0.5rem !important;
  }

  .card__media {
    border-radius: 6px !important;
    height: 120px;
  }

  .card__content {
    padding-top: 0.5rem !important;
    padding-bottom: 0.25rem !important;
  }

  .chip {
    margin-bottom: 0.5rem !important;
  }

  .card__footer {
    margin-top: 8px;
  }

  .card__avatar__name {
    img {
      width: 12px;
      height: 12px;
      margin-inline-end: 4px;
    }
  }
}
