.about__img {
  position: relative;
  background-image: url('../../../assets/images/about-otrovato-img.jpg');
  background-size: cover;
  width: 100%;
  height: 60vh;
  max-height: 373px;
  border-radius: 20px;
}
.about__overlay {
  position: absolute;
  top: 0;
  width: 60%;
  background-color: var(--magentaPink);
  height: 100%;
  border-radius: 20px 0 0 20px;
}
.about__img__header {
  position: absolute;
  top: 100px;
  left: 60px;
  max-width: 600px;
  color: white;
  z-index: 50;
  padding: 0 12px;
}
.card__icon {
  width: 40px;
  height: 40px;
}
.africa__container {
  position: relative;
  padding-top: 80px;
  margin-left: 10%;
}
.africa__info {
  position: absolute;
  top: 38%;
  left: 32%;
}
@media (max-width: 768px) {
  .about__img {
    max-height: 120px;
    border-radius: 6px;
  }
  .about__overlay {
    width: 70%;
    border-radius: 6px 0 0 6px;
  }
  .about__img__header {
    top: 30px;
    left: 17px;
    max-width: 60%;
    color: white;
    z-index: 50;
    padding: 0;
  }
  .card__icon {
    width: 32px;
    height: 32px;
  }
  .africa__container {
    margin-left: 0;
    padding-top: 0;
  }
  .africa__info {
    text-align: center;
    top: 44%;
    left: 10%;
  }
  .africa__image {
    width: 100%;
  }
  .card__icon {
    width: 32px;
    height: 32px;
  }
}
