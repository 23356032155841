.vendor__section {
  display: flex;
  background: linear-gradient(268deg, var(--deepYellow) 23.58%, #f18a27 87.12%);
  gap: 24px;
  padding: 24px;
  border-radius: 0 !important;
  justify-content: space-between;
}

.vendor__card {
  width: 150px;
  height: 150px;
  img {
    width: 100%;
    height: 100%;
  }
}

.vendor__section__info {
  display: flex;
  gap: 24px;
}

.vendor__section__actions {
  display: flex;
  flex-direction: column;
  flex-basis: 26%;
  gap: 12px;
}

.vendor__contact {
  background-color: var(--white) !important;
  color: var(--deepYellow) !important ;
  border: 1px solid transparent !important;
}

.vendor__contact:hover {
  background-color: transparent !important;
  color: var(--white) !important;
  border: 1px solid var(--white) !important;
}

a.vr {
  width: 100%;
}

.vendor__vr {
  background-color: transparent !important;
  color: var(--white) !important;
  border: 1px solid var(--white) !important;
  width: 100%;
}

.vendor__vr:hover {
  background-color: var(--white) !important;
  color: var(--deepYellow) !important;
  border: 1px solid transparent !important ;
}

.filter__box {
  border: 1px solid var(--lightGray);
  padding: 24px 12px;
}

@media  (pointer: coarse) {
  .vendor__section {
    flex-direction: column;
    gap: 12px;
  }

  .vendor__card {
    width: 75px;
    height: 75px;
  }

  .vendor__section__info {
    gap: 12px;
  }

  .vendor__section__img {
    width: 70px;
    height: 70px;
  }

  .vendor__section__actions {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .vendor__contact {
    flex-grow: 1;
  }

  a.vr {
    flex-grow: 1;
    width: unset;
  }
}
