.lottie__container {
  width: 262px;
  height: 262px;
  margin-bottom: -10px;
}

@media (max-width: 768px) {
  .lottie__container {
    width: 170px;
    height: 170px;
    margin-bottom: 20px;
  }
}
