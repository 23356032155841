.promotion__section__container {
  padding-top: 44px;
  padding-bottom: 48px;
}

.section__container {
  padding-block: 48px;
}

.section__odd {
  background-color: var(--background);
}

.section__even {
  background-color: var(--white);
}

.section__faq {
  background-color: var(--antiFlashWhite);
}

.categories__section__container {
  box-shadow: 0.5px -0.5px 2px 0px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .promotion__section__container {
    padding-block: 20px;
  }

  .section__container {
    padding-block: 20px;
  }
}
