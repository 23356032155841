.history__item__container {
  background-color: var(--white);
  border: 1px solid var(--antiFlashWhite);
  padding: 12px;
  margin-bottom: 16px;
}

.history__vendor__name {
  color: var(--darkGray);
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
