@import url('./swiper.scss');
@import url('./algolia-theme.scss');

body {
  font-size: 16px !important;
  line-height: normal !important;
  font-weight: normal !important;
}

html {
  scrollbar-gutter: stable;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove__gutter {
  scrollbar-gutter: unset;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--midLightGray);
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background-color: var(--antiFlashWhite);
}

/* Firefox */
::-moz-scrollbar {
  width: 6x;
}
::-moz-scrollbar-thumb {
  background-color: var(--midLightGray);
  border-radius: 4px;
}
::-moz-scrollbar-track {
  background-color: var(--antiFlashWhite);
}

:root {
  --aa-text-color-rgb: var(--darkGrayRgb);
  --aa-primary-color-rgb: var(--magentaPinkRgb);
  --aa-input-border-color-rgb: var(--lightGrayRgb);
  --aa-search-input-height: 40px;
  --aa-muted-color-rgb: var(--grayRgb);
  --aa-panel-shadow: var(--boxShadow);
  --aa-panel-max-height: max-content;
  --aa-icon-color-rgb: var(--midLightGrayRgb);
  --aa-selected-color-rgb: var(--secondaryLightRgb);
  --aa-input-icon-size: 24px;

  // Border Radius
  --border-radius-normal: 8px;

  // Header
  --complete-profile-height: 54px;
}
.grecaptcha-badge {
  display: none;
}

@media (pointer: coarse) {
  html {
    scrollbar-gutter: unset;
  }
}
