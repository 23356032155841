.about__layout {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--violet);
  opacity: 70%;
  height: 100%;
}
.about__img {
  position: relative;
  background-image: url('../../../assets/images/old-about-otrovato-img.png');
  background-size: cover;
  width: 100%;
  height: 60vh;
  max-height: 420px;
}
.about__img__header {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  color: white;
  text-align: center;
  z-index: 50;
  padding: 0 12px;
}
.about__info {
  padding-top: 74px;
  padding-bottom: 100px;
}
