:root {
  --swiper-pagination-color: var(--deepYellow);
  --swiper-pagination-bullet-height: 10px;
  --swiper-pagination-bullet-width: 10px;
}

/********** Bullets *********/
.swiper-pagination-bullet-active {
  width: 28px;
  border-radius: 10px;
}

/********** Arrows *********/
.swiper-with-arrows {
  position: relative;
  .custom-swiper-button-prev,
  .custom-swiper-button-next {
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.swiper-with-arrows:hover {
  .custom-swiper-button-prev,
  .custom-swiper-button-next {
    opacity: 1;
  }
}

.custom-swiper-button-prev,
.custom-swiper-button-next {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  // margin-top: -22px;
  z-index: 10;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 999999px;
  border: 1px solid var(--violet);
  transform: translateY(-50%);
  svg {
    stroke: var(--violet);
  }
}

.custom-swiper-button-prev {
  left: 20px;
  right: auto;

  svg {
    transform: rotate(180deg);
  }
}

.custom-swiper-button-next {
  right: 20px;
  left: auto;
}

.custom-swiper-button-prev:hover,
.custom-swiper-button-next:hover {
  background-color: var(--violet);
  svg {
    stroke: var(--white);
  }
}

.disabled-swiper {
  opacity: 0 !important;
  pointer-events: none;
}
/********** End Arrows *********/

@media (max-width: 768px) {
  :root {
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-bullet-width: 8px;
  }
  .swiper-pagination-bullet-active {
    width: 18px;
    border-radius: 10px;
  }
}

@media (pointer: coarse)  {
  .swiper-with-arrows {
    .custom-swiper-button-prev,
    .custom-swiper-button-next {
      opacity: 0 !important;
    }
  }
}
