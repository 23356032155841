.blog__html {
  padding-block: 48px;
  line-height: 34px;
  p {
    margin-bottom: 1rem;
    color: var(--midDarkGray);
  }
  strong {
    color: var(--darkGray);
  }
}

@media (max-width: 768px) {
  .blog__html {
    padding-block: 20px;
  }
}
