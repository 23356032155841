.call__to__action__container {
  background-image: url('../../../../assets/images/homepage-rfq.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 63px 100px;
}

.rfq__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 75%;
}

.homepage__rfq__header {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 20px;
}
.homepage__rfq__fields {
  margin-top: 20px;
  justify-items: center;
  width: 100% !important;
  padding-bottom: 20px;
  position: relative;
  z-index: 4;
}
@media (max-width: 576px) {
  .call__to__action__container {
    background-image: url('../../../../assets/images/homepage-rfq-responsive.png');
    background-size: cover;
    padding-block: 20px;
    padding-inline: 20px;
  }
  .homepage__rfq__header {
    margin-bottom: 0;
  }
  .rfq__art {
    padding: 0;
    align-items: flex-start;
  }
  .homepage__rfq__fields {
    margin-top: -38px !important;
    padding-bottom: 0;
    padding-right: 20px;
    width: 100vw !important;
  }
}
