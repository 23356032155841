.categories__container {
  overflow: scroll;
  width: 45%;
  height: 100%;
  background-color: var(--secondaryLight);
  min-height: 30px;
  padding-block: 20px;
}
.categories__container::-webkit-scrollbar,
.sub__categories__container::-webkit-scrollbar {
  display: none;
}

.category__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 4px 8px 4px 20px;
}

.selected {
  background-color: var(--white);
  padding: 4px 0px 4px 20px;
}

.sub__categories__container {
  overflow: scroll;
  width: 55%;
  padding: 20px 8px 20px 20px;
  display: flex;
  flex-direction: column;
}
