.gateway__bg {
  background-image: url('../../assets/images/gateway-bg.png');
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
  padding-right: 130px;
}

.overlay__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(18, 18, 18, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 53%;
  margin: auto;
}

.introduction__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.grid__container {
  padding-block: 48px;
}

.grid__item__text {
  width: 85%;
  color: var(--midLightGray);
}

.company__logo {
  margin-bottom: 48px;
  svg {
    height: 70px;
  }
}

.next__button {
  width: 35%;
}

.card__icon {
  width: 40px;
  height: 40px;
}

@media (max-width: 1024px) {
  .content__container {
    width: 70%;
    padding-inline: 20px;
  }

  .introduction__container {
    align-items: flex-start;
  }

  .grid__container {
    padding-block: 20px;
  }

  .company__logo {
    margin-bottom: 20px;
    svg {
      height: 41px;
    }
  }

  .next__button {
    width: 100%;
  }

  .card__icon {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 767px) {
  .gateway__bg {
    background-image: url('../../assets/images/gateway-mb-bg.png');
    padding-left: 20px;
    padding-right: 20px;
  }

  .overlay__bg {
    padding-inline: 10px;
  }

  .content__container {
    width: 100%;
    padding-inline: 20px;
  }

  .introduction__container {
    align-items: flex-start;
  }

  .company__logo {
    margin-bottom: 20px;
    svg {
      height: 41px;
    }
  }

  .card__icon {
    width: 32px;
    height: 32px;
  }
}
