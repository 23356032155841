.vendor__card__responsive {
  background-color: var(--white) !important;
  padding: 20px;
  box-shadow: -0.5px -1px 2px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 0px !important;
}
.vendor__card__responsive__container {
  display: flex;
  margin-bottom: 1rem;
  img {
    background-color: var(--white);
    border: 1px solid var(--lightGray);
    padding: 5px;
    width: 40px;
    height: 40px;
    margin-inline-end: 12px;
  }
}
.vendor__card__description {
  padding: 0 8px;
  margin: auto;
  width: 85%;
  max-height: 48px;
}

.vendor__card__description__content {
  max-height: 48px;
  color: var(--midLightGray) !important;
  * {
    color: var(--midLightGray) !important;
  }
}
