.vendor__card__float {
  background-color: var(--white) !important;
  padding: 16px 20px;
  border-radius: 0px !important;
  border: 1px solid var(--antiFlashWhite);
  box-shadow: -0.5px 0.5px 2px 0px rgba(0, 0, 0, 0.24) !important;
  width: 100%;
}

.vendor__card__float__image__container {
  width: 57px;
  height: 57px;
  padding: 6px;
  background-color: var(--white);
  border: 0.5px solid var(--lightGray);
  margin-inline-end: 24px;
}
.vendor__card__float__image__container div {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 43px;
  height: 43px;
}

.vendor__card__float__description {
  color: var(--midLightGray) !important;
  * {
    color: var(--midLightGray) !important;
  }
}

.vendor__card__float__actions__float__container {
  margin-top: 12px;
  button {
    width: 50% !important;
  }
  a {
    width: 50% !important;
    button {
      width: 100% !important;
    }
  }
}
