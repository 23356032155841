.unread__bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--green);
  width: 16px;
  height: 16px;
  color: var(--white);
}
