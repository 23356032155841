.header__container {
  display: flex;
  justify-content: space-between;
  background-color: var(--antiFlashWhite);
  padding: 12px 24px;
}

@media (pointer: coarse) {
  .header__container {
    background-color: unset;
    margin-bottom: 20px;
  }
}
