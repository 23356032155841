.tier__prices__slider .slick-slide #slider-box {
  border: 1px solid var(--antiFlashWhite);
  padding: 12px;
  width: max-content !important;
  margin-inline-end: 6px;
}

.tier__prices__slider {
  .slick-arrow.slick-next {
    right: 20px;
  }
  .slick-arrow.slick-prev {
    left: 20px;
  }
}

@media (max-width: 768px) {
  .tier__prices__slider .slick-slide #slider-box {
    padding: 10px;
  }
}
