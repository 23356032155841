.accordion__icon {
  height: 24px;
  width: 24px;
  stroke-width: 2;
  stroke: var(--violet);
  transform: rotate(90deg);
}

.faq__description {
  color: var(--midDarkGray) !important;
  font-size: 16px !important;
  * {
    color: var(--midDarkGray) !important;
    font-size: 16px !important;
  }
  ul {
    padding: 0 0 0 36px;
    list-style: disc;
    color: var(--midDarkGray);
  }
  li {
    line-height: 30px;
  }
  strong {
    font-weight: normal;
  }
}

@media (max-width: 768px) {
  .faq__description {
    font-size: 14px;
  }
}
