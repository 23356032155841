.category__card {
  display: flex;
  flex-direction: column;
  border: 0 !important;
  padding: 24px;
  height: 100%;
  background-color: var(--lightGray);
  border-radius: 8px;
}

.category__media__container {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 309px;
  height: 413px !important;
  padding-block: 4px;
}

.category__media__container > img {
  width: 130px;
  height: 130px;
}

.category__card__content {
  padding: 0px !important;
  padding-bottom: 0px !important;
}

@media (max-width: 768px) {
  .category__card {
    padding: 12px;
  }

  .category__media__container {
    width: 222px;
    height: 310px;
  }

  .category__media__container > img {
    width: 100px;
    height: 100px;
  }
}
