.checked__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50vw;
  max-width: 540px;
  margin-top: -120px;
}
.lottie__container {
  width: 540px;
  margin-bottom: 12px;
}
.info {
  z-index: 1000;
  text-align: center;
  margin-top: -92px;
}

@media (max-width: 768px) {
  .checked__container {
    width: 100%;
    margin-top: -20px;
  }
  .lottie__container {
    width: 300px;
  }

  .info {
    margin-top: -80px;
  }
}
