.terms {
  overflow: auto;
  height: 100%;
  p {
    color: var(--midDarkGray);
  }
  h3,
  h4 {
    color: var(--darkGray);
    padding: 12px 0;
    font-weight: bold;
  }
}
