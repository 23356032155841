.message__center__container {
  height: 90vh;
  overflow: hidden;
}
.message__center__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  padding-inline: 24px;
  background-color: var(--white);
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: var(--boxShadow);
}

.channel__list {
  background-color: var(--white);
  padding-top: 53px;
}

.checked__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.lottie__container {
  width: 92px;
  height: 92px;
  margin-bottom: 12px;
}

.info {
  color: var(--midLightGray);
  text-align: center;
  width: 250px;
}

.divider {
  width: 90vw;
  position: fixed;
  bottom: 55px;
  z-index: 10000000;
}

@media (max-width: 768px) {
  .checked__container {
    width: 100%;
  }
}
