.category__card {
  height: 50px;
  width: fit-content;
  position: relative;
}

.browse__by__category__header {
  background-color: var(--white);
  padding: 12px 0;
  box-shadow: var(--boxShadow);
  margin-top: -24px;
}

.browse__by__category__slider::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  z-index: 99;
  background: linear-gradient(to left, white, transparent);
}

.category__tile__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  height: 45px;
  border-radius: 16px;
  background-color: var(--white);
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    margin-inline-end: 4px;
  }
}

.category__icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 767px) {
  .browse__by__category__header {
    padding: 20px 0;
  }
  .category__card {
    height: 308px;
    width: 220px;
  }
  .category__image {
    padding: 20px 32px;
    height: 100%;
  }
  .category__image {
    display: flex;
    align-items: flex-end;
    padding: 20px 32px;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .browse__by__category__slider::after {
    content: '';
    display: none;
  }
}
