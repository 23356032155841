.drawer__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow-y: scroll;
}

.drawer__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  z-index: 1000;
  min-height: 56px;
  padding-inline: 20px;
}

.content__container {
  padding-inline: 20px;
  overflow-y: scroll;
  .gs__image__container {
    position: relative;
    width: max-content;
    border: 1px solid #bdbdbd;
    border-radius: 8px;

    img {
      border-radius: 8px;
      width: 170px;
      aspect-ratio: 1/1;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(10px, -6px);
      background-color: var(--green);
      border-radius: 9999px;
      color: var(--white);
      padding: 2px;
      font-size: 10px;
      width: 18px;
      height: 18px;
    }
  }
}

.drawer__footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -10px 10px -10px #afafaf80;
  background-color: var(--white);
  z-index: 1000;
  margin-top: auto;
  padding: 20px;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .drawer__header {
    padding-inline: 50px;
  }

  .content__container,
  .drawer__footer {
    padding-inline: 50px;
  }
}

@media (pointer: coarse)  {
  .drawer__header {
    background-color: var(--secondaryLight);
  }
}
