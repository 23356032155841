.category__card {
  height: 411px;
  width: 310px;
  position: relative;
}

.category__image {
  display: flex;
  align-items: flex-end;
  padding: 24px 40px;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.category__tile__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  height: 45px;
  border-radius: 16px;
  background-color: var(--white);
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    margin-inline-end: 4px;
  }
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--violet);
  }
}

@media (max-width: 767px) {
  .category__card {
    height: 308px;
    width: 220px;
  }
  .category__image {
    padding: 20px 32px;
    height: 100%;
  }
  .category__image {
    display: flex;
    align-items: flex-end;
    padding: 20px 32px;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
}
