.custom__channel__list {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.search__container {
  height: 11.6%;
  flex-shrink: 0;
  background-color: rgba(249, 249, 249, 0.94);
  filter: drop-shadow(0px 0.33329999446868896px 0px rgba(0, 0, 0, 0.3));
  backdrop-filter: calc(27px);
  justify-content: center;
  align-items: center;
  display: flex;
}
