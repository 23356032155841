.home__main__header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
  .nav__left {
    display: grid;
    grid-auto-flow: column;
    column-gap: 62px;
  }
  .nav__right {
    display: flex;
    align-items: center;
    height: 40px;
    .account__menu {
      height: 20px;
      display: flex;
      align-items: center;
      flex-grow: 0;
      text-align: center;
    }
  }
}

@media (max-width: 1200px) {
  .nav__left {
    column-gap: 8px !important;
  }
}

@media (max-width: 768px) {
  .main__toolbar {
    padding-block: 20px !important;
  }
}
