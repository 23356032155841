.main__details__container {
  display: flex;
  flex-direction: column;
  height: 517px;
  overflow: hidden;
}

.content__container {
  flex: 1;
  overflow-y: scroll;
  padding-inline-end: 24px;
}

.content__container::-webkit-scrollbar {
  width: 0px;
}

.title__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4px;
  transition: box-shadow 0.3s ease-out;
}

.title__container__scrolled {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.vendor__link {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.vendor__icon__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  border: 1px solid var(--lightGray);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.copy__link {
  display: flex;
  padding: 8px;
  border: 0.5px solid var(--lightGray);
  cursor: pointer;
  height: 40px;
  appearance: none;
  position: relative;
}
.copy__icon {
  color: var(--darkGray);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-inline-start: 8px;
  stroke-dasharray: 50;
  transition: all 300ms ease-in-out;
}
.check__icon {
  color: var(--magentaPink);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-inline-start: 8px;
  stroke-dasharray: 50;
  transition: all 300ms ease-in-out;
}

.moq__container {
  background-color: rgba(var(--magentaPinkRgb), $alpha: 0.1);
  padding: 12px;
  width: max-content;
  margin-bottom: 24px;
}

.show__more__container {
  z-index: 1;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 52.5%);
  bottom: 0;
}

.gs__image__container {
  position: relative;
  width: max-content;

  img {
    border-radius: 8px;
    width: 180px;
    aspect-ratio: 1/1;
  }

  p {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(10px, -10px);
    background-color: var(--green);
    border-radius: 9999px;
    color: var(--white);
    padding: 4px;
  }
}

@media (pointer: coarse)  {
  .main__details__container {
    height: max-content;
    padding-inline-end: 0px;
    overflow: auto;
  }
  .main__details__container::-webkit-scrollbar {
    display: none;
  }
  .title__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
  }
  .moq__container {
    padding: 6px 8px;
    width: max-content;
    margin-bottom: 20px;
  }
}
