.card {
  cursor: pointer;
  padding: 24px;
  width: 100%;
}

.card__header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .card {
    padding: 20px;
  }
  .card__header__container {
    margin-bottom: 12px;
  }
}
