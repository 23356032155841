.notification__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--deepYellow);
  border-radius: 9999px !important;
  width: auto;
  height: 14px;
  padding: 2px 6px;
  color: var(--white);
  position: absolute;
  bottom: 30px;
  transform: translateX(10px);
}

.bottom__nav__icon {
  width: 24px;
  height: 24px;
}
