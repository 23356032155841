.rfq__content {
  border: 1px solid var(--antiFlashWhite);
  padding: 24px;
}

.main__file__container {
  background-color: var(--antiFlashWhite);
  cursor: pointer;
  position: relative;
  margin-inline-end: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1;
  padding: 20px;
}

.main__file__container > img {
  max-height: 100%;
}

.file__container {
  cursor: pointer;
  position: relative;
  width: 100px;
  height: 100px;
  background-color: var(--antiFlashWhite);
  margin-inline-end: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.file__container img,
.file__container a {
  height: 100%;
}

.file__container svg {
  height: 75%;
  width: 75%;
}

.responsive__main__link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.download__button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 49px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(228, 228, 228, 0.9);
  color: var(--midDarkGray);
  svg {
    height: auto;
    width: auto;
    fill: var(--midDarkGray);
  }

  &:hover {
    background-color: rgba(87, 87, 87, 0.9);
    color: var(--white);
    svg {
      fill: var(--white);
    }
  }
}

@media (pointer: coarse) {
  .download__button {
    display: none;
  }
}

@media (max-width: 1200px) {
  .main__file__container {
    margin-inline-end: 0px;
    padding: 12px;
  }

  .file__container {
    width: 85px;
    height: 85px;
    margin-inline-end: 8px;
  }
}

@media (max-width: 576px) {
  .file__container {
    width: 65px;
    height: 65px;
  }
}
