.refinement__chip {
  display: flex;
  align-items: center;
  border: 0.5px solid var(--midLightGray);
  color: var(--midLightGray);
  padding: 4px 10px;
  width: fit-content;
  > * {
    height: 32px;
  }
  p {
    display: flex;
    align-items: center;
    color: var(--midLightGray);
    font-size: 14px;
    font-weight: 600;
  }
  svg {
    cursor: pointer;
    fill: var(--midLightGray);
    width: 1rem;
    height: 1rem;
  }
}

.refinement__chip:hover {
  border: 1px solid var(--deepYellow);
  background-color: var(--deepYellow);
  p {
    color: var(--white);
  }
  svg {
    fill: var(--white);
  }
}
