.blogs__section__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.read__more {
  display: flex;
  align-items: center;
  span {
    color: var(--midDarkGray);
    text-transform: capitalize;
  }
}

@media (max-width: 768px) {
  .blogs__section__container {
    margin-bottom: 12px;
  }

  .read__more {
    svg {
      width: 1rem;
    }
    a {
      font-size: 10px;
    }
  }
}
